import React, {useState, useEffect} from "react";
import { v4 as uuidv4 } from 'uuid';
import Gun from 'gun';
import {Container, Row, Col, Navbar, Form, FormControl, InputGroup, Button, Table} from "react-bootstrap"
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

const gun = Gun(['hsab-hrm-001.herokuapp.com']);

function App() {
  
    const [deviceID, setDeviceID] = useState(undefined);
    const [userID, setUserID] = useState(undefined);
    const [unregistered, setUnregistered] = useState(true);
    const [location, setLocation] = useState([]);
    const [email, setEmail] = useState('');
    
    function EmailInput() {
      return (
        <Row>
          <Col>
            <Form className='my-3 px-3' onSubmit={(event) => event.preventDefault()}>
              <InputGroup>
                <InputGroup.Text>User ID:</InputGroup.Text>
                <FormControl className='emailInput' name='email' id='email' type='email' placeholder='Enter email address' value={email} onInput={(event) => {setEmail(event.target.value)}}/>
                <Button className='emailButton' type='submit' onClick={() => {handleClick()}}>Submit</Button>
              </InputGroup>
            </Form>
          </Col>
       </Row>
      )
    }

    function handleClick(){
      const device = gun.get(deviceID);
      device.get('user').put(email);
      device.on((device) => {
        setUserID(device.user);
        setUnregistered(false);
      });
    }

    function Debug(){
      const device = gun.get(deviceID);
      var deviceObject = {}
      device.on((device) => {
        deviceObject = device;
      });

      return (
        <Row>
          <Col>
            <Table striped bordered variant='dark'>
              <tbody>
                <tr>
                  <td>User:</td>
                  <td>{userID}</td>
                </tr>
                <tr>
                  <td>Device:</td>
                  <td>{deviceID}</td>
                </tr>
                <tr>
                  <td>Location:</td>
                  <td>{location.lat}, {location.long}</td>
                </tr>
                <tr>
                  <td>Log:</td>
                  <td>
                    <Table striped bordered variant='dark'>
                      <thead>
                        <tr>
                          <th>Time</th>
                          <th>Latitude</th>
                          <th>Longitude</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          Object.entries(deviceObject).map((entry,index) => {
                            if (entry[0] === '_' || entry[0] === 'user')
                            {
                              return(null)
                            }
                            else {
                              var time = new Date(parseInt(entry[0])).toLocaleString('en-PK');
                              var position = JSON.parse(entry[1])
                              return (
                                <tr key={index}>
                                  <td>{time}</td>
                                  <td>{position.lat}</td>
                                  <td>{position.long}</td>
                                </tr>
                              )
                            }
                          })
                        }
                      </tbody>
                    </Table>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      )
    }

    useEffect(() => {
      if (userID !== undefined && userID !== '') {
        const device = gun.get(deviceID);
        const timestamp = Number(Date.now().toPrecision(10)).toString();
        const loc = JSON.stringify(location)
        device.get(timestamp).put(loc);
        //device.on(() => {
        //  console.log('Recorded entry:\nDevice: ' + deviceID + '\nTimestamp: ' + timestamp + '\nLocation:' + JSON.stringify(JSON.parse(loc)));
        //});
      }
    },[location])

    useEffect(() => {

      // get location
      navigator.geolocation.getCurrentPosition(function(location) {
        setLocation({
          lat: location.coords.latitude,
          long: location.coords.longitude
        });
      });

      // get Device ID
      if(localStorage.getItem('deviceID') === null) {           // if Device ID doesn't exist
        localStorage.setItem('deviceID', uuidv4());             // make one
      }
      setDeviceID(localStorage.getItem('deviceID'));

      // get User ID
      const device = gun.get(deviceID);
      device.on((device) => {
        if (device.user !== undefined) {            // if user exists
          setUnregistered(false);
          setUserID(device.user);
        }
        else {                                      // is user does not exist
          console.log('Device not registered to any user!');
        }
      });

    },[deviceID, userID]);

    return (
      <Container fluid className='p-0 m-0'>
        <Row>
          <Navbar bg="dark" variant="dark">
            <Navbar.Brand>
              <img alt="HSAB Logo" src="/logo.png" width="30" height="30" className="d-inline-block align-top" />{' '}
              HSAB HRM
            </Navbar.Brand>
          </Navbar>
        </Row>
        {unregistered ? EmailInput() : <div></div>}
        <Row>
          <Debug />
        </Row>
      </Container>
    );
}

export default App;
